import api from '../axios';
const resource = '/api/mp/';

export default {
  getLeaves: (paginate, page, filterQuery) =>
    api.get(
      `${resource}salaried-staff/leaves?page=${page}&paginate=${paginate}&${filterQuery}`
    ),
  requestLeave: (payload) =>
    api.post(`${resource}salaried-staff/leaves`, payload),
  editLeave: (id, payload) =>
    api.put(`${resource}salaried-staff/leaves/${id}`, payload),
  deleteLeave: (id) => api.delete(`${resource}salaried-staff/leaves/${id}`),
  adminRequestedLeave: (paginate, page, filterQuery) =>
    api.get(
      `${resource}practice-admin/leaves?page=${page}&paginate=${paginate}&filter[state]=requested&${filterQuery}`
    ),
  adminApprovedLeave: (paginate, page, filterQuery) =>
    api.get(
      `${resource}practice-admin/leaves?page=${page}&paginate=${paginate}&filter[state]=approved&${filterQuery}`
    ),
  adminRejectedLeave: (paginate, page, filterQuery) =>
    api.get(
      `${resource}practice-admin/leaves?page=${page}&paginate=${paginate}&filter[state]=rejected&${filterQuery}`
    ),
  approveRequestedLeave: (id) =>
    api.post(`${resource}practice-admin/leaves/${id}/approve`),
  rejectRequestedLeave: (id) =>
    api.post(`${resource}practice-admin/leaves/${id}/reject`),
  requestAdminAndStaffLeaves: (payload) =>
    api.post(`${resource}practice-admin/leaves/add-leave`,payload)
};
